import { useRef, useState } from "react";
import axios_v1 from "../plugin/axios"

const Contact = () =>{
    const ref_name = useRef(null);
    const ref_email = useRef(null);
    const ref_phone_number = useRef(null);
    const ref_content = useRef(null);
    const send = async () =>{
      let data= {
        email:ref_email.current.value,
        phone_number:ref_phone_number.current.value,
        name:ref_name.current.value,
        content:ref_content.current.value,
      }
       for (const key in data) {
         if(data[key].trim().length == 0)
          {
            alert("Bảm bảo rằng bạn không bỏ sót thông tin");
            return;
          }
      }
      try {
      const response = await axios_v1.post(`contacts`,data);
      alert(response.data.data)
     } catch (error) {
       alert("Có lỗi xảy ra!")
     }
    }
  return (

  <div className="container">
    <div className="row mt-3">
    
      <form className=" d-flex flex-wrap col-md-8 col-12 px-0 m-auto">
        <div className="col-12">
        <h3 >
         Liên hệ
        </h3>
        </div>
        <div className="col-md-12 col-12">
          <div className="form-group">
            <label htmlFor="">Tên</label>
            <input ref={ref_name} type="text" name="" id="" className="form-control" placeholder=""/>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label htmlFor="">Số điện thoại</label>
            <input ref={ref_phone_number} type="text" name="" id="" className="form-control" placeholder=""/>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label htmlFor="">Email</label>
            <input ref={ref_email} type="text" name="" id="" className="form-control" placeholder=""/>
          </div>
        </div>
        <div className=" col-12">
          <div className="form-group">
            <label htmlFor="">Nội dung</label>
            <textarea ref={ref_content} className="form-control">

          </textarea>
          </div>
        </div>
        <div className="col-12">
          <button type="button" onClick={()=>send()} className="btn btn-primary float-right">
            Gửi
          </button>
        </div>
          <div className="clearfix"></div>
      </form >
    </div>
  </div>
  )
}
export default Contact;