import { Fragment } from "react";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import { Outlet } from "react-router-dom";

const Root = () =>{
return (
  <Fragment>
    <Header ></Header>
    <div className="min--500">
      
       <Outlet />
    </div>
    <Footer></Footer>
   </Fragment>
);
}
export default Root;