import { configureStore } from '@reduxjs/toolkit';

import postReducer from './post';

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// })
const store = configureStore({
  reducer: { post: postReducer},

  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;
