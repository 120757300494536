import React, { useState} from 'react';
import $ from 'jquery'

const PostContext = React.createContext({
  item: null,
  setItem: (item) => {},
  callModal: () => {},
  closeModal: () => {},
});

export const PostContextProvider = (props) => {
  
  const [item, setItem] = useState(null);
  const setItemNow = (value)=>{
    setItem(value)
  }
 
  const callModal = () =>{
    $('#modal-post-detail').modal('show')
  }
  const closeModal = () =>{
    $('#modal-post-detail').modal('hide')
  }
  
 

  const contextValue = {
    item: item,
    setItem: setItemNow,
    callModal: callModal,
    closeModal: closeModal,
  };

  return (
    <PostContext.Provider value={contextValue}>
      {props.children}
    </PostContext.Provider>
  );
};

export default PostContext;
