import { useEffect, useState } from 'react';
import axios_v1 from "../plugin/axios"
import parse from 'html-react-parser'

const About = () =>{
    const [about,setAbout] = useState(null);
    const initData =  async() =>{
      const response = await axios_v1.get(`about-me`);
      setAbout(response.data.data);
    }
    useEffect( () => {
      try {
        initData();
      } catch (error) {
        
      }
    },[])
      return (
      <div className="container">
         <div className="row">
          <div className="col-12">
            {about && about.name && <h3 >{about.name}</h3>}
          </div>
         </div>
         {about && 
          <div className="row py-2">
          <div className="col-md-4">
            <img src={about.avatar} alt="" />
          </div>
          <div className="col-md-8">
            {about.description  && <div >
              {
parse(about.description)
              }
            </div>}
            
          </div>
        </div>
         }
      </div>
    );
}
export default About;