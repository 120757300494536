import StoryItem from "../components/stories/StoryItem";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { getPosts } from "../store/post";
import { PostContextProvider } from "../store/context/post/post-context";
import PostDetail from "../components/modals/PostDetail";

const Home = () => {
  const dispatch = useDispatch();
  const {items,paginate} = useSelector((state) => state.post);
  const [timer,setTimer] = useState(null);
  const list = useRef();
  useEffect(() => {
    dispatch(getPosts());
  }, []);
 

  useEffect(() => {
    const onScroll = () => {
      let st = (document.documentElement.scrollTop);
      let scroll_current =  (st + document.documentElement.clientHeight);
      // & paginate && paginate.current_page < paginate.lastPage
      if(scroll_current >= list.current.clientHeight ) 
      {
        if(paginate.current_page < paginate.last_page )
        {
         
             if(timer)
             {
              clearTimeout(timer);
              setTimer(null);
             }
              setTimer(setTimeout(() => {
                dispatch(getPosts({page:paginate.current_page + 1}));  
              }, 1000))
        }
      }
  
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [items,paginate,timer]);

  return (
    <Fragment>
      <PostContextProvider>
        <div  ref={list}>
          <div className="container">
            <div className="row"   >
              {items.map((item, index) => (
                <div key={index} className="col-12 col-md-6 mt-4">
                  <StoryItem
                    name={item.name}
                    avatar={item.avatar}
                    created_at={item.created_at}
                    content={item.content}
                    description={item.description}
                  ></StoryItem>
                </div>
              ))}
            </div>
          </div>
        </div>
        <PostDetail />
      </PostContextProvider>
    </Fragment>
  );
};
export default Home;
