import { Fragment, useContext } from "react";
import ReactDOM from "react-dom";
import PostContext from "../../store/context/post/post-context";
import moment from "moment";
const Modal = (props) => {
  const postCtx = useContext(PostContext);

  const item = postCtx.item;
  return (
    <div
      className="modal fade"
      id="modal-post-detail"
      role="dialog"
      aria-labelledby="modelTitleId"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={()=>{
                postCtx.setItem(null);
                postCtx.closeModal();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {item && (
                    <div>
                      <div className="card ">
                        <div className="div__img--effect cursor--pointer">
                          <img
                            className="rounded-0 card-img-top  object-fit-contain"
                            src={item.avatar}
                            alt="123"
                          />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title mb-0">{item.name}</h5>
                          <p className="card-text text-text-muted mb-0">{item.description}</p>
                         
                          <div>
                            {item.content}
                          </div>
                          <i className="float-right font--07">
                            {moment(item.created_at).format("DD-MM-YYYY HH:mm")}
                          </i>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const PostDetail = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Modal />, document.getElementById("root-modal"))}
    </Fragment>
  );
};
export default PostDetail;
