import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios_v1 from "../plugin/axios";
import { faL } from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line no-unused-vars
import qs from "qs"
const initialPostState = {
  items: [],
  paginate: {
    current_page: 1,
    lastPage: 0,
    last_page: 0,
    perPage: 0,
    total: 0,
  },
  loading: false,
};
export const getPosts = createAsyncThunk("post/getPosts", async (filter = null) => {
  try {
    const response = await axios_v1.get(`posts?${qs.stringify(filter)}`);
    return response;
  } catch (error) {
    return error;
  }
});

const postSlice = createSlice({
  name: "post",
  initialState: initialPostState,
  extraReducers: {
    // [getPosts.pending]: (state) => {
    //   state.loading = true
    // },
    [getPosts.fulfilled]: (state, { payload }) => {
      let { data, ...paginate } = payload.data.data;
      console.log(paginate.current_page)
      if(paginate.current_page === 1)
      {
        state.items = [];
      }
      state.items = state.items.concat(data);
      state.paginate.current_page = paginate.current_page;
      state.paginate.lastPage = paginate.lastPage;
      state.paginate.last_page = paginate.last_page;
      state.paginate.perPage = paginate.perPage;
      state.paginate.total = paginate.total;
    },
    [getPosts.rejected]: (state, { payload }) => {
      // state.loading = false
      console.log(payload);
    },
  },
});

export const postActions = postSlice.actions;

export default postSlice.reducer;
