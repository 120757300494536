import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import GeneralContext from "../../store/context/general/general-context";

const Footer = () =>{
  const social = useContext(GeneralContext);
  console.log(social);
 return (
  <div className=" text-center py-3" >
      <span className=" font__footer">
        Copyright 2023, By JOYPHAM 
      </span>
      {
        social.item && social.item.github_link && <div>
         <a className="text-dark" href={social.item.github_link} target="_blank" rel="noreferrer">
         <FontAwesomeIcon icon="fa-brands fa-github" />
         </a>
         </div>
      }
  </div>
 )
}
export default Footer;