import React, { useEffect, useState} from 'react';
import axios_v1 from "../../../plugin/axios"

const GeneralContext = React.createContext({
  item: null,
  setItem: (item) => {},
});

export const GeneralContextProvider = (props) => {
  
  const [item, setItem] = useState(null);
  const setItemNow = async ()=>{
    const response = await axios_v1.get(`network-social`);
    setItem(response.data.data);
  }
 
  useEffect( () => {
    try {
      setItemNow();
    } catch (error) {
      
    }
  },[])
  
 

  const contextValue = {
    item: item,
    setItem: setItemNow,
  };

  return (
    <GeneralContext.Provider value={contextValue}>
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralContext;
