import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState,useEffect, useContext } from "react";

import zalo from "../../assets/Images/logo_zalo.svg";
import classes from "./header.module.css";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import GeneralContext from "../../store/context/general/general-context";

const FadeTransition = (props) => (
  <CSSTransition
    {...props}
    classNames={{
      enter: "fade-enter",
      enterActive: "fade-enter-active",
      exit: "fade-exit",
    }}
    addEndListener={(node, done) => {
      node.addEventListener("transitionend", done, false);
    }}
  />
);

const Header = () => {
 

  const social = useContext(GeneralContext);

  const [menu, setMenu] = useState(0);
  return (
    <Fragment>
      <header className="bg-grey shadow">
        <div className="container">
          <div className="d-block row py-2 position-relative">
            <div className="col-12 d-flex d-lg-none justify-content-between align-items-center">
              <button onClick={() => setMenu(!menu)} className="btn">
                <SwitchTransition mode={menu ? "in-out" : "out-in"}>
                  <FadeTransition key={menu ? true : false}>
                    {!menu ? (
                      <FontAwesomeIcon icon="fa-solid fa-bars" />
                    ) : (
                      <FontAwesomeIcon icon="fa-solid fa-xmark" />
                    )}
                  </FadeTransition>
                </SwitchTransition>
              </button>

              <b className="">JOY</b>
            </div>
            <div
              className={`${
                menu ? classes.menu__mobile : ""
              } d-lg-flex d-none px-2`}
            >
              <div className={`col-lg-5 col-12 px-lg-0 px-3`}>
                <ul
                  className={`d-flex  ${
                    menu ? classes["menu--url__mobile"] : ""
                  }`}
                >
                  {/* <li className="d-lg-block d-none">
                    <Link className="d-block px-lg-2" to="/">
                      <FontAwesomeIcon
                        className="text-dark"
                        icon="fa-solid fa-house"
                      />
                    </Link>
                  </li> */}
                  
                  <li>
                  <Link className="d-block px-lg-2" to="/">
                      <FontAwesomeIcon
                        className="text-dark"
                        icon="fa-solid fa-book"
                      />{" "}
                      <span className="text-dark">Góc nhỏ</span>
                      </Link>
                  </li>
                  <li>
                    <Link className="d-block px-lg-2" to="/ve-joy">
                      <FontAwesomeIcon
                        className="text-dark"
                        icon="fa-solid fa-user-tie"
                      />{" "}
                      <span className="text-dark">Về tôi</span>
                    </Link>
                  </li>
                  <li>
                     <Link className="d-block px-lg-2" to="/lien-he">
                      <FontAwesomeIcon
                        className="text-dark"
                        icon="fa-solid fa-envelope"
                      />{" "}
                      <span className="text-dark">Liên hệ</span>
                      </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-12 d-none d-lg-block text-center px-lg-0 px-3" >
                <b className="">JOYPHAM</b>
              </div>
              <div className="col-lg-5 col-12 px-lg-0 px-3">
                <ul className="d-flex justify-content-lg-end">
                  { social.item && social.item.facebok_link &&
                        <li>
                        <a target="_blank" href={social.item.facebok_link} className="d-block pl-lg-2 pr-lg-2 pr-2" rel="noreferrer">
                          <FontAwesomeIcon
                            icon="fa-brands fa-facebook"
                            className="text-dark"
                          />
                        </a>
                        </li>
                  }
                  { social.item && social.item.zalo_link &&
                  <li>
                    <a href={social.item.zalo_link} className="d-block pl-lg-2 pr-lg-2 pr-2">
                      <img
                        src={zalo}
                        className={`${classes.zalo__icon}`}
                        alt=""
                      />
                    </a>
                  </li>
}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
