import moment from "moment";
import PostContext from "../../store/context/post/post-context";
import { useContext } from "react";
const StoryItem = (props) => {
   const postCtx = useContext(PostContext);
 
   return (
      <div onClick={()=>{
         postCtx.setItem(props);postCtx.callModal();
      }} className="card cursor--pointer" >
         <div   className="div__img--effect ">
          <img className="rounded-0 card-img-top img__default" src={props.avatar} alt="123"/>
         </div>
         <div className="card-body">
         <h5 className="card-title">{props.name}</h5>
         <p className="card-text">{props.description}</p>
         <span className="float-right">
            {moment(props.created_at).format("DD-MM-YYYY HH:mm")}
         </span>
         <div className="clearfix"></div>
     
         </div>
      </div>
   );
}
export default StoryItem